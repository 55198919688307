<template>
  <div
    id="wage-report"
    class="p-1"
  >
    <h3 class="text-center mb-2">
      Company Consumer Wage Calculator
    </h3>
    <b-row class="align-items-baseline pb-2">
      <b-col
        class="d-flex align-items-baseline"
        cols="12"
        md="6"
      >
        <b-form-group
          label="Company"
          class="w-100"
        >
          <b-overlay :show="isLoading">
            <v-select
              v-model="company"
              class="v-selection"
              :clearable="!!company"
              :options="companies"
              :reduce="c => c.id"
              @input="getEmployees"
            />
          </b-overlay>
        </b-form-group>

        <b-button
          v-b-modal.create-temp-company-modal
          class="d-none d-md-block ml-1"
          style="width: 40%;"
          variant="success"
        >
          <feather-icon icon="PlusIcon" />
          Create New
        </b-button>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="text-right"
      >
        <b-button
          v-b-modal.create-temp-company-modal
          class="d-block-inline d-md-none float-left"
          variant="success"
        >
          <feather-icon icon="PlusIcon" />
          Create New
        </b-button>

        <span
          v-b-tooltip.hover="'Please select a company first'"
          :disabled="company"
        >
          <b-button
            v-b-modal.full-report-modal
            variant="primary"
            :disabled="!company"
          >
            <feather-icon icon="FileTextIcon" />
            Full Report
          </b-button>
        </span>
        <b-button
          v-if="self.isAdmin"
          variant="primary"
          class="ml-1 mt-1 mt-md-0"
          @click="() => { $router.push({ name: 'calculator-settings' }) }"
        >
          <feather-icon icon="SettingsIcon" />
          Settings
        </b-button>
      </b-col>
    </b-row>
    <b-overlay :show="isLoading">
      <b-table-simple
        responsive
        class="wage-table"
      >
        <b-thead>
          <b-tr>
            <b-th />
            <b-th />
            <b-th>
              Onsite
            </b-th>
            <b-th>
              Remote
            </b-th>
            <b-th>
              Current Monthly Spend
            </b-th>
            <template>
              <!-- Adjusted -->
              <b-th>
                Onsite
              </b-th>
              <b-th>
                Remote Domestic
              </b-th>
              <b-th>
                Remote Offshore
              </b-th>
              <b-th>
                Adjusted Monthly Spend
              </b-th>
            </template>
            <!-- Adjusted -->
            <b-th>
              Difference
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr class="total">
            <b-td class="text-center">
              <b> Total</b>
            </b-td>
            <b-td />
            <b-td>
              <h6>{{ formatAmount(totalOnSite.current, false) }}</h6>
            </b-td>
            <b-td>
              <h6>{{ formatAmount(totalRemote.current, false) }}</h6>
            </b-td>
            <b-td
              class="text-right"
              :style="IS_MOBILE() ? 'minWidth: 180px' : ''"
            >
              <div class="calc-border">
                <div class="d-flex justify-content-between align-items-baseline">
                  <h6>Total Salary:</h6>
                  <h6>{{ formatAmount(getCurrentTotal, false) }}</h6>
                </div>
                <div class="d-flex justify-content-between">
                  <h6>Benefits Cost:</h6>
                  <h6>{{ formatAmount(totalCoverageCosts.isCurrent ? totalCoverageCosts.isCurrent : 0, totalCoverageCosts.isCurrent ? 1 : 0) }}</h6>
                </div>
              </div>
            </b-td>
            <template>
              <b-td>
                <h6>{{ formatAmount(totalOnSite.recommended, false) }}</h6>
              </b-td>
              <b-td>
                <h6>{{ formatAmount(totalRemote.recommended, false) }}</h6>
              </b-td>
              <b-td>
                <h6>{{ formatAmount(totalRemoteOffshore, false) }}</h6>
              </b-td>
              <b-td
                class="text-right"
                :style="IS_MOBILE() ? 'minWidth: 180px' : ''"
              >
                <div class="calc-border">
                  <div class="d-flex justify-content-between align-items-baseline">
                    <h6>Total Salary:</h6>
                    <h6>{{ formatAmount(getRecommendedTotal, false) }}</h6>
                  </div>
                  <div class="d-flex justify-content-between">
                    <h6>Benefits Cost:</h6>
                    <h6>{{ formatAmount(totalCoverageCosts.isRecommended ? totalCoverageCosts.isRecommended : 0, totalCoverageCosts.isRecommended ? 1 : 0) }}</h6>
                  </div>
                </div>
              </b-td>
              <b-td :style="IS_MOBILE() ? 'minWidth: 140px' : ''">
                <div class="px-1">
                  <div class="d-flex justify-content-between align-items-baseline">
                    <h6>Saved: </h6>
                    <h6>{{ formatAmount(getRecommendedTotal ? getCurrentTotal - getRecommendedTotal : 0, false) }}</h6>
                  </div>
                  <h6 :class="getSavedTotal > 0 ? 'text-success' : 'text-danger'">
                    Saved: {{ getSavedTotal || 0 }}%
                  </h6>
                  <h6 class="text-danger">
                    Diff: {{ getDifferenceTotal || 0 }}%
                  </h6>
                </div>
              </b-td>
            </template>
          </b-tr>
          <template v-for="t,index in tableTypes">
            <b-tr class="details">
              <b-td colspan="2">
                <b> {{ t.name }}</b>
              </b-td>
              <b-td>
                <h5 class="mb-0">
                  {{ formatAmount(categorizedEmployees[t.class].onSite ? categorizedEmployees[t.class].onSite : 0, false) }}
                </h5>
              </b-td>
              <b-td>
                <h5 class="mb-0">
                  {{ formatAmount(categorizedEmployees[t.class].remote ? categorizedEmployees[t.class].remote : 0, false) }}
                </h5>
              </b-td>
              <b-td>
                <h5 class="mb-0">
                  Line Item Tot.
                </h5>
              </b-td>
              <template>
                <b-td>
                  <h5 class="mb-0">
                    {{ formatAmount(categorizedRecEmployees[t.class].onSite ? categorizedEmployees[t.class].onSite : 0, false) }}
                  </h5>
                </b-td>
                <b-td>
                  <h5 class="mb-0">
                    {{ formatAmount(categorizedRecEmployees[t.class].remote ? categorizedEmployees[t.class].remote : 0, false) }}
                  </h5>
                </b-td>
                <b-td>
                  <h5 class="mb-0">
                    {{ formatAmount(categorizedRecEmployees[t.class].remoteOffshore ? categorizedRecEmployees[t.class].remoteOffshore : 0, false) }}
                  </h5>
                </b-td>
                <b-td>
                  <h5 class="mb-0">
                    Line Item Tot.
                  </h5>
                </b-td>
                <b-td>
                  <h5 class="mb-0">
                    Line Item Diff.
                  </h5>
                </b-td>
              </template>
            </b-tr>
            <template v-if="t.childs">
              <b-tr
                v-for="child, i in t.childs"
                :key="t.name+child.name"
              >
                <b-td>
                  {{ child.name }}
                </b-td>
                <b-td>
                  <b-button
                    variant="outline-primary"
                    @click="openInputProviderModal(t,child)"
                  >
                    Input Provider
                  </b-button>
                </b-td>
                <b-td>
                  <div v-if="categorizedEmployees[t.class][child.name] && categorizedEmployees[t.class][child.name].onSite.length">
                    <p class="mb-0">
                      {{ categorizedEmployees[t.class][child.name].onSite.length }} |
                      {{ formatAmount(ACCUMULATE(categorizedEmployees[t.class][child.name].onSite, 'salary'), false) }}
                    </p>
                    <b-link
                      :id="`fullEmployeeCount-onsite-${index}-${i}`"
                      class="small"
                    >
                      Details
                    </b-link>
                    <b-popover
                      :target="`fullEmployeeCount-onsite-${index}-${i}`"
                      title="Details"
                      triggers="hover"
                    >
                      <p
                        v-for="employee, salary in getEmployeesBySalary(categorizedEmployees[t.class][child.name].onSite)"
                        :key="salary"
                        class="mb-0 text-right"
                      >
                        {{ employee.length }} x
                        {{ formatAmount(salary, false) }}
                      </p>
                      <hr class="my-0">
                      <span class="font-weight-bold">
                        Total: {{ formatAmount(ACCUMULATE(categorizedEmployees[t.class][child.name].onSite, 'salary'), false) }}
                      </span>
                    </b-popover>
                  </div>

                  <span v-else>$ 0</span>
                </b-td>
                <b-td>
                  <div v-if="categorizedEmployees[t.class][child.name] && categorizedEmployees[t.class][child.name].remote.length">
                    <p class="mb-0">
                      {{ categorizedEmployees[t.class][child.name].remote.length }} |
                      {{ formatAmount(ACCUMULATE(categorizedEmployees[t.class][child.name].remote, 'salary'), false) }}
                    </p>
                    <b-link
                      :id="`fullEmployeeCount-remote-${index}-${i}`"
                      class="small"
                    >
                      Details
                    </b-link>
                    <b-popover
                      :target="`fullEmployeeCount-remote-${index}-${i}`"
                      title="Details"
                      triggers="hover"
                    >
                      <p
                        v-for="employee, salary in getEmployeesBySalary(categorizedEmployees[t.class][child.name].remote)"
                        :key="salary"
                        class="mb-0 text-right"
                      >
                        {{ employee.length }} x
                        {{ formatAmount(salary, false) }}
                      </p>
                      <hr class="my-0">
                      <span class="font-weight-bold">
                        Total: {{ formatAmount(ACCUMULATE(categorizedEmployees[t.class][child.name].remote, 'salary'), false) }}
                      </span>
                    </b-popover>
                  </div>
                  <span v-else>
                    $ 0
                  </span>
                </b-td>
                <b-td>
                  <h5 class="mb-0">
                    {{ formatAmount(categorizedEmployees[t.class].monthlySpent ? categorizedEmployees[t.class].monthlySpent[child.name] : 0, false) }}
                  </h5>
                </b-td>
                <template>
                  <b-td class="adjustment">
                    <div v-if="categorizedRecEmployees[t.class][child.name] && categorizedRecEmployees[t.class][child.name].onSite.length">
                      <p class="mb-0">
                        {{ categorizedRecEmployees[t.class][child.name].onSite.length }} |
                        {{ formatAmount(ACCUMULATE(categorizedRecEmployees[t.class][child.name].onSite, 'salary'), false) }}
                      </p>
                      <b-link
                        :id="`fullEmployeeCount-onsite-${index}-${i}`"
                        class="small"
                      >
                        Details
                      </b-link>
                      <b-popover
                        :target="`fullEmployeeCount-onsite-${index}-${i}`"
                        title="Details"
                        triggers="hover"
                      >
                        <p
                          v-for="employee, salary in getEmployeesBySalary(categorizedEmployees[t.class][child.name].onSite)"
                          :key="salary"
                          class="mb-0 text-right"
                        >
                          {{ employee.length }} x
                          {{ formatAmount(salary, false) }}
                        </p>
                        <hr class="my-0">
                        <span class="font-weight-bold">
                          Total: {{ formatAmount(ACCUMULATE(categorizedEmployees[t.class][child.name].onSite, 'salary'), false) }}
                        </span>
                      </b-popover>
                    </div>
                    <span v-else>$ 0</span>
                  </b-td>
                  <b-td class="adjustment">
                    <div v-if="categorizedRecEmployees[t.class][child.name] && categorizedRecEmployees[t.class][child.name].remote.length">
                      <p class="mb-0">
                        {{ categorizedRecEmployees[t.class][child.name].remote.length }} |
                        {{ formatAmount(ACCUMULATE(categorizedRecEmployees[t.class][child.name].remote, 'salary'), false) }}
                      </p>
                      <b-link
                        :id="`fullRecEmployeeCount-remote-${index}-${i}`"
                        class="small"
                      >
                        Details
                      </b-link>
                      <b-popover
                        :target="`fullRecEmployeeCount-remote-${index}-${i}`"
                        title="Details"
                        triggers="hover"
                      >
                        <p
                          v-for="employee, salary in getEmployeesBySalary(categorizedEmployees[t.class][child.name].remote)"
                          :key="salary"
                          class="mb-0 text-right"
                        >
                          {{ employee.length }} x
                          {{ formatAmount(salary, false) }}
                        </p>
                        <hr class="my-0">
                        <span class="font-weight-bold">
                          Total: {{ formatAmount(ACCUMULATE(categorizedEmployees[t.class][child.name].remote, 'salary'), false) }}
                        </span>
                      </b-popover>
                    </div>
                    <span v-else>
                      $ 0
                    </span>
                  </b-td>
                  <b-td class="adjustment">
                    <div v-if="categorizedRecEmployees[t.class][child.name] && categorizedRecEmployees[t.class][child.name].remoteOffshore.length">
                      <p class="mb-0">
                        {{ categorizedRecEmployees[t.class][child.name].remoteOffshore.length }} |
                        {{ formatAmount(ACCUMULATE(categorizedRecEmployees[t.class][child.name].remoteOffshore, 'salary'), false) }}
                      </p>
                      <b-link
                        :id="`fullRecEmployeeCount-remoteOffshore-${index}-${i}`"
                        class="small"
                      >
                        Details
                      </b-link>
                      <b-popover
                        :target="`fullRecEmployeeCount-remoteOffshore-${index}-${i}`"
                        title="Details"
                        triggers="hover"
                      >
                        <p
                          v-for="employee, salary in getEmployeesBySalary(categorizedRecEmployees[t.class][child.name].remoteOffshore)"
                          :key="salary"
                          class="mb-0 text-right"
                        >
                          {{ employee.length }} x
                          {{ formatAmount(salary, false) }}
                        </p>
                        <hr class="my-0">
                        <span class="font-weight-bold">
                          Total: {{ formatAmount(ACCUMULATE(categorizedRecEmployees[t.class][child.name].remoteOffshore, 'salary'), false) }}
                        </span>
                      </b-popover>
                    </div>
                    <span v-else>
                      $ 0
                    </span>
                  </b-td>
                  <b-td class="adjustment">
                    <h5>
                      {{ formatAmount(categorizedRecEmployees[t.class].monthlySpent ? categorizedRecEmployees[t.class].monthlySpent[child.name] : 0, false) }}
                    </h5>
                  </b-td>
                  <b-td>
                    <h5 class="mb-0">
                      {{ formatAmount(getDifferenceAmount(t.class, child.name), false) }}
                    </h5>
                  </b-td>
                </template>
              </b-tr>
            </template>
          </template>
        </b-tbody>
      </b-table-simple>
    </b-overlay>

    <!-- Input Provider Modal -->
    <input-provider-modal
      v-if="activeInputType && activeInputChild"
      :active-input-type="activeInputType"
      :active-input-child="activeInputChild"
      :company="getCurrentCompany"
      :employees="employeesByOperation.employees[activeInputType.class][activeInputChild.name]"
      :regions="regions"
      :recommended-employees="employeesByOperation.recommendedEmployees[activeInputType.class][activeInputChild.name]"
      @employees-updated="getEmployees"
      @new-company-created="getMyCompanies(true)"
    />
    <!-- Full Report Modal -->
    <full-report-modal
      v-if="employees && recommendedEmployees"
      :employees="employees"
      :recommended-employees="recommendedEmployees"
      :current-total="getCurrentTotal"
      :recommended-total="getRecommendedTotal"
      :regions="regions"
      :total-coverage-costs="totalCoverageCosts"
    />
    <!-- Company Name Model -->
    <b-modal
      id="create-temp-company-modal"
      title="Create New Company"
      no-close-on-backdrop
      ok-title="Create"
      @ok="createCompany"
    >
      <b-overlay :show="isLoading">
        <validation-observer ref="calculatorCompanyForm">
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="company name"
          >
            <b-form-group label="Company Name">
              <b-form-input
                v-model="companyName"
                type="text"
                placeholder="Company Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </validation-observer>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import {
  BTableSimple, BThead, BTbody, BTr, BTh, BTd, BOverlay, BRow, BCol, BButton, BFormGroup, VBTooltip, BPopover, BFormInput, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useApollo from '@/plugins/graphql/useApollo'
import { getUserData } from '@/auth/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import InputProviderModal from './components/InputProviderModal.vue'
import FullReportModal from './components/FullReportModal.vue'

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BOverlay,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    InputProviderModal,
    FullReportModal,
    BFormInput,
    BPopover,
    BLink,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      self: getUserData(),
      companies: [
        { label: 'Select a Company', id: null },
      ],
      company: null,
      companyName: '',
      employees: {},
      rawEmployees: [],
      recommendedEmployees: {},
      categorizedEmployees: {
        salary: [],
        hourly: [],
        contract: [],
      },
      categorizedRecEmployees: {
        salary: [],
        hourly: [],
        contract: [],
      },
      employeesByOperation: {
        employees: {
          salary: {},
          hourly: {},
          contract: {},
        },
        recommendedEmployees: {
          salary: {},
          hourly: {},
          contract: {},
        },
      },
      totalRemote: {
        current: 0,
        recommended: 0,
      },
      totalOnSite: {
        current: 0,
        recommended: 0,
      },
      totalRemoteOffshore: 0,
      showAdjusted: true,
      activeInputChild: null,
      activeInputType: null,
      tableTypes: {
        salary: {
          name: 'Salaried Employees',
          numbers: 'Numbers',
          class: 'salary',
          adjusted: {},
          childs: {
            process: {
              name: 'Process',
              adjusted: {},
            },
            services: {
              name: 'Services',
              adjusted: {},
            },
          },

        },
        hourly: {
          name: 'Hourly Employees',
          class: 'hourly',
          adjusted: {},
          childs: {
            process: {
              name: 'Process',
              adjusted: {},
            },
            services: {
              name: 'Services',
              adjusted: {},
            },
          },
        },
        contract: {
          name: 'Contracted Providers',
          class: 'contract',
          adjusted: {},
          childs: {
            process: {
              name: 'Process',
              adjusted: {},
            },
            services: {
              name: 'Services',
              adjusted: {},
            },
          },
        },
      },
      isLoading: false,
      regions: [],
      totalCoverageCosts: {
        isCurrent: 0,
        isRecommended: 0,
      },
    }
  },
  computed: {
    getCurrentTotal() {
      return this.totalRemote.current + this.totalOnSite.current
    },
    getRecommendedTotal() {
      return this.totalRemote.recommended + this.totalOnSite.recommended + this.totalRemoteOffshore
    },
    getSavedTotal() {
      if (this.getRecommendedTotal === 0 || this.getCurrentTotal === this.getRecommendedTotal) return 0
      return (100 - this.getDifferenceTotal).toFixed(2)
    },
    getDifferenceTotal() {
      if (this.getRecommendedTotal === 0 || this.getCurrentTotal === this.getRecommendedTotal) return 0
      return ((this.getRecommendedTotal / this.getCurrentTotal) * 100).toFixed(2)
    },
    getCurrentCompany() {
      return this.company ? this.companies.find(company => company.id === this.company) : null
    },
  },
  watch: {
    '$route.params.company': {
      handler(val) {
        if (val) {
          this.company = val
          this.$nextTick(() => { this.getEmployees() })
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getMyCompanies()
    this.getRegions()
  },
  methods: {
    getMyCompanies(created = false) {
      const allCompanies = [
        { label: 'Select a Company', id: null },
      ]
      this.isLoading = true
      useApollo.calculator.getMyCompanies().then(response => {
        const companies = response.data.me.tempCompanies.map(company => ({
          label: company.name,
          id: company.id,
        }))
        allCompanies.push(...companies)
        this.companies = allCompanies
      }).finally(() => {
        if (created) {
          this.company = this.companies[this.companies.length - 1].id
          this.getEmployees()
        }
        this.isLoading = false
      })
    },
    mapEmployees(employees, filterKey) {
      return employees.filter(emp => emp[filterKey]).map(emp => ({
        ...emp,
        dependents: emp.dependents.data,
      }))
    },
    getRegions() {
      if (!this.regions.length) {
        useApollo.calculator.getRegions().then(response => {
          this.regions = response.data.regions.data
        })
      }
    },
    getTotalCoverage() {
      const groups = ['isCurrent', 'isRecommended']

      groups.forEach(group => {
        const employees = this.rawEmployees.filter(employee => employee[group] === 1)
        const employeeCoverageCost = this.getCoverageAmount(employees)
        let dependentsCoverageCost = 0
        employees.forEach(employee => {
          dependentsCoverageCost += this.getCoverageAmount(employee.dependents.data, employee.region.id)
        })

        this.totalCoverageCosts[group] = employeeCoverageCost + dependentsCoverageCost
      })
      this.$nextTick(() => { this.isLoading = false })
    },
    getCoverageAmount(holders, regionId = null) {
      let coverageAmount = 0
      holders.forEach(holder => {
        const id = regionId ?? holder.region.id
        const region = this.regions.find(r => r.id === id)
        const coverages = this.group(region.coverages?.data, 'category', 'name')
        const contributions = this.group(holder.contributions, 'coverageCategory', 'name')
        const employerContribution = {}

        Object.entries(coverages).forEach(([key, value]) => {
          const cov = value.find(coverage => coverage.minAge <= holder.age && coverage.maxAge >= holder.age)
          const contribution = contributions[key] ? contributions[key][0].contribution : 0

          if (contribution !== 0) {
            employerContribution[key] = cov ? cov[holder.coverageQuality.toLowerCase()] * (contribution / 100) : 0
            coverageAmount += employerContribution[key]
          }
        })
      })
      return coverageAmount / 12
    },
    group(array, parentKey, childKey) {
      return array.reduce((r, a) => {
        // eslint-disable-next-line no-param-reassign
        r[a[parentKey][childKey]] = r[a[parentKey][childKey]] || []
        r[a[parentKey][childKey]].push(a)
        return r
      }, Object.create(null))
    },
    getEmployees() {
      if (this.company) {
        this.clearCalculator()
        this.$nextTick(() => {
          this.isLoading = true
          useApollo.calculator.getAllEmployees({ companyId: this.company }).then(response => {
            const data = response.data.me.tempCompanies[0]

            if (data) {
              const employees = this.mapEmployees(data.employees?.data, 'isCurrent')
              const recommendedEmployees = this.mapEmployees(data.employees?.data, 'isRecommended')

              this.rawEmployees = data.employees?.data
              this.employees = this.GROUP_BY(employees, 'employeeType') ?? {}
              this.recommendedEmployees = this.GROUP_BY(recommendedEmployees, 'employeeType') ?? {}

              const employeesByWork = this.GROUP_BY(employees, 'workModality')
              const recEmployeesByWork = this.GROUP_BY(recommendedEmployees, 'workModality')

              this.totalRemote = {
                // eslint-disable-next-line no-nested-ternary
                current: (employeesByWork.REMOTE ? this.ACCUMULATE(employeesByWork.REMOTE, 'salary') : 0) + (employeesByWork.REMOTEOFFSHORE ? this.ACCUMULATE(employeesByWork.REMOTEOFFSHORE, 'salary') : 0),
                recommended: recEmployeesByWork.REMOTE ? this.ACCUMULATE(recEmployeesByWork.REMOTE, 'salary') : 0,
              }
              this.totalOnSite = {
                current: employeesByWork.ONSITE ? this.ACCUMULATE(employeesByWork.ONSITE, 'salary') : 0,
                recommended: recEmployeesByWork.ONSITE ? this.ACCUMULATE(recEmployeesByWork.ONSITE, 'salary') : 0,
              }
              this.totalRemoteOffshore = recEmployeesByWork.REMOTEOFFSHORE ? this.ACCUMULATE(recEmployeesByWork.REMOTEOFFSHORE, 'salary') : 0

              this.$nextTick(() => {
                this.getTotalCoverage()
                this.categorizedEmployees = {
                  salary: this.employeesBy('employees', 'SALARY', 'salary'),
                  hourly: this.employeesBy('employees', 'HOURLY', 'hourly'),
                  contract: this.employeesBy('employees', 'CONTRACTEDPROVIDER', 'contract'),
                }
                this.categorizedRecEmployees = {
                  salary: this.employeesBy('recommendedEmployees', 'SALARY', 'salary'),
                  hourly: this.employeesBy('recommendedEmployees', 'HOURLY', 'hourly'),
                  contract: this.employeesBy('recommendedEmployees', 'CONTRACTEDPROVIDER', 'contract'),
                }
              })

              if (!data.employees.data.length) {
                this.employeesByOperation = {
                  employees: {
                    salary: {},
                    hourly: {},
                    contract: {},
                  },
                  recommendedEmployees: {
                    salary: {},
                    hourly: {},
                    contract: {},
                  },
                }
              }
            } else this.isLoading = false
          })
        })
      } else this.clearCalculator()
    },
    employeesBy(empGroup, employeeType, type) {
      if (this[empGroup] && this[empGroup][employeeType]) {
        const employees = this.GROUP_BY(this[empGroup][employeeType], 'operationType')
        const Services = {
          onSite: employees?.SERVICES?.filter(emp => emp.workModality === 'ONSITE') ?? [],
          ...(
            empGroup === 'recommendedEmployees'
              ? {
                remote: employees?.SERVICES?.filter(emp => emp.workModality === 'REMOTE') ?? [],
                remoteOffshore: employees?.SERVICES?.filter(emp => emp.workModality === 'REMOTEOFFSHORE') ?? [],
              }
              : { remote: employees?.SERVICES?.filter(emp => emp.workModality === 'REMOTE') ?? [] }
          ),
        }

        const Process = {
          onSite: employees?.PROCESS?.filter(emp => emp.workModality === 'ONSITE') ?? [],
          ...(
            empGroup === 'recommendedEmployees'
              ? {
                remote: employees?.PROCESS?.filter(emp => emp.workModality === 'REMOTE') ?? [],
                remoteOffshore: employees?.PROCESS?.filter(emp => emp.workModality === 'REMOTEOFFSHORE') ?? [],
              }
              : { remote: employees?.PROCESS?.filter(emp => emp.workModality === 'REMOTE' || emp.workModality === 'REMOTEOFFSHORE') ?? [] }
          ),
        }

        let remoteOffshore = 0
        const remote = this.ACCUMULATE(Services.remote, 'salary') + this.ACCUMULATE(Process.remote, 'salary')
        const onSite = this.ACCUMULATE(Services.onSite, 'salary') + this.ACCUMULATE(Process.onSite, 'salary')
        if (empGroup === 'recommendedEmployees') {
          remoteOffshore = this.ACCUMULATE(Services.remoteOffshore, 'salary') + this.ACCUMULATE(Process.remoteOffshore, 'salary')
        }
        const monthlySpent = {
          Process: employees?.PROCESS ? this.ACCUMULATE(employees?.PROCESS, 'salary') : 0,
          Services: employees?.SERVICES ? this.ACCUMULATE(employees?.SERVICES, 'salary') : 0,
        }

        const employeesByWork = this.GROUP_BY(this[empGroup][employeeType], 'workModality')
        this.employeesByOperation[empGroup][type] = {
          Services: {
            onSite: employeesByWork.ONSITE?.filter(emp => emp.operationType === 'SERVICES') ?? [],
            ...(empGroup === 'recommendedEmployees'
              ? {
                remote: employeesByWork.REMOTE?.filter(emp => emp.operationType === 'SERVICES') ?? [],
                remoteOffshore: employeesByWork.REMOTEOFFSHORE?.filter(emp => emp.operationType === 'SERVICES') ?? [],
              }
              : { remote: employeesByWork.REMOTE?.filter(emp => emp.operationType === 'SERVICES') ?? [] }
            ),
          },
          Process: {
            onSite: employeesByWork.ONSITE?.filter(emp => emp.operationType === 'PROCESS') ?? [],
            ...(empGroup === 'recommendedEmployees'
              ? {
                remote: employeesByWork.REMOTE?.filter(emp => emp.operationType === 'PROCESS') ?? [],
                remoteOffshore: employeesByWork.REMOTEOFFSHORE ? employeesByWork.REMOTEOFFSHORE.filter(emp => emp.operationType === 'PROCESS') : [],
              }
              : { remote: employeesByWork.REMOTE?.filter(emp => emp.operationType === 'PROCESS') ?? [] }
            ),
          },
        }

        return {
          Services,
          Process,
          remote,
          onSite,
          monthlySpent,
          remoteOffshore,
        }
      }
      return {}
    },
    getSaved(employeeType, operationType) {
      const diff = this.getDifference(employeeType, operationType)
      if (diff === 0) return 0
      return (100 - diff).toFixed(2)
    },
    getDifference(employeeType, operationType) {
      const currentMonthly = this.categorizedEmployees[employeeType]?.monthlySpent ? this.categorizedEmployees[employeeType].monthlySpent[operationType] : 0
      const recommendedMonthly = this.categorizedRecEmployees[employeeType]?.monthlySpent ? this.categorizedRecEmployees[employeeType].monthlySpent[operationType] : 0

      if (recommendedMonthly === 0 || currentMonthly === recommendedMonthly) return 0

      return Number.isFinite(recommendedMonthly / currentMonthly) ? ((recommendedMonthly / currentMonthly) * 100).toFixed(2) : 100
    },
    openInputProviderModal(type, child) {
      this.activeInputType = type
      this.activeInputChild = child
      this.$nextTick(() => {
        this.$bvModal.show('inputProviderModal')
      })
    },
    createCompany(bvModal) {
      bvModal.preventDefault()
      const params = {
        input: {
          name: this.companyName,
          createdBy: this.self.id,
        },
      }
      this.$refs.calculatorCompanyForm.validate().then(success => {
        if (success) {
          this.company = null
          this.isLoading = true
          useApollo.calculator.createTempCompany(params).then(() => {
            this.showSuccessMessage({
              data: {
                message: 'Company created successfully!',
              },
            })
          }).finally(() => {
            this.getMyCompanies(true)
            this.isLoading = false
            this.$bvModal.hide('create-temp-company-modal')
          })
        }
      })
    },
    clearCalculator() {
      this.employees = {}
      this.rawEmployees = []
      this.recommendedEmployees = {}
      this.categorizedEmployees = {
        salary: [],
        hourly: [],
        contract: [],
      }
      this.categorizedRecEmployees = {
        salary: [],
        hourly: [],
        contract: [],
      }
      this.employeesByOperation = {
        employees: {
          salary: {},
          hourly: {},
          contract: {},
        },
        recommendedEmployees: {
          salary: {},
          hourly: {},
          contract: {},
        },
      }
      this.totalRemote = {
        current: 0,
        recommended: 0,
      }
      this.totalOnSite = {
        current: 0,
        recommended: 0,
      }
      this.totalRemoteOffshore = 0
      this.totalCoverageCosts = {
        isCurrent: 0,
        isRecommended: 0,
      }
      this.isLoading = false
    },
    getEmployeesBySalary(array) {
      return this.GROUP_BY(array, 'salary')
    },
    getDifferenceAmount(type, operationType = null) {
      let current = 0
      let recommended = 0

      if (operationType) {
        current = this.categorizedEmployees[type].monthlySpent ? this.categorizedEmployees[type].monthlySpent[operationType] : 0
        recommended = this.categorizedRecEmployees[type].monthlySpent ? this.categorizedRecEmployees[type].monthlySpent[operationType] : 0
      } else {
        current = this.categorizedEmployees[type].monthlySpent ? this.categorizedEmployees[type].monthlySpent.Process + this.categorizedEmployees[type].monthlySpent.Services : 0
        recommended = this.categorizedRecEmployees[type].monthlySpent ? this.categorizedRecEmployees[type].monthlySpent.Process + this.categorizedRecEmployees[type].monthlySpent.Services : 0
      }

      return recommended - current
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/base/pages/calculator.scss";
</style>
