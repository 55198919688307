<template>
  <b-modal
    id="inputProviderModal"
    size="xl"
    ok-title="Submit"
    hide-footer
  >
    <div
      id="wage-report"
      class="p-0 p-md-1"
    >
      <div class="align-items-center pb-2">
        <b-row class="pb-2 justify-content-center text-center">
          <h3>
            Input Provider Dashboard
          </h3>
        </b-row>

        <b-tabs pills>
          <b-tab title="Company Employees">
            <current-employees
              :employees="employees"
              :company="company"
              :active-input-type="activeInputType"
              :active-input-child="activeInputChild"
              :regions="regions"
              @employees-updated="() => { $emit('employees-updated') }"
              @new-company-created="() => { $emit('new-company-created') }"
            />
          </b-tab>

          <b-tab
            title="Recommended Employees"
            :lazy="lazyRecommended"
          >
            <recommended-employees
              :current-employees="employees"
              :employees="recommendedEmployees"
              :company="company"
              :active-input-type="activeInputType"
              :active-input-child="activeInputChild"
              :regions="regions"
              @employees-updated="() => { $emit('employees-updated') }"
              @no-lazy="lazyRecommended = false"
              @new-company-created="() => { $emit('new-company-created') }"
            />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import CurrentEmployees from './InputEmployees.vue'
import RecommendedEmployees from './RecommendedEmployees.vue'

export default {
  components: {
    BTabs,
    BTab,
    CurrentEmployees,
    RecommendedEmployees,
  },
  props: {
    activeInputType: {
      type: [Array, Object],
      default: () => {},
    },
    activeInputChild: {
      type: [Array, Object],
      default: () => {},
    },
    employees: {
      type: Object,
      default: () => {},
    },
    recommendedEmployees: {
      type: Object,
      default: () => {},
    },
    company: {
      type: Object,
      default: () => {},
    },
    regions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      lazyRecommended: true,
    }
  },
}
</script>
