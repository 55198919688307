<template>
  <div>
    <b-overlay :show="isLoading">
      <div
        style="border:2px solid blue; border-radius: 20px;"
        class="p-1 my-2"
      >
        <!-- Add New Worker -->
        <b-row>
          <b-col>
            <b-button
              class="mb-1 p-1"
              variant="outline-primary"
              @click="addWorker('os')"
            >
              <feather-icon icon="PlusIcon" />
              Add OS Employee
            </b-button>
            <b-button
              variant="outline-success"
              class="ml-0 ml-md-2 mb-1 p-1"
              @click="addWorker('rm')"
            >
              <feather-icon
                icon="PlusIcon"
              />
              Add RM Employee
            </b-button>

            <b-button
              v-if="confirmModal === 'recommended-employees-modal'"
              variant="outline-success"
              class="ml-0 ml-md-2 mb-1 p-1"
              @click="addWorker('rmo')"
            >
              <feather-icon
                icon="PlusIcon"
              />
              Add RM Offshore Employee
            </b-button>
          </b-col>
        </b-row>

        <!-- Worker Info -->
        <div
          v-for="typeWorker,i in allWorkers"
          :key="i"
          style="border:1px solid red; border-radius: 20px;"
          class="p-1 m-0 m-md-1"
          :class="{ 'd-none': !typeWorker || !typeWorker.length }"
        >
          <validation-observer ref="calculatorForm">
            <div
              v-for="worker, index in typeWorker"
              :key="index"
            >
              <div class="d-flex justify-content-between align-items-center">
                <h5>{{ i.toUpperCase() }} Employee {{ index + 1 }}</h5>
                <!-- Remove Worker -->
                <b-button
                  v-b-tooltip.hover="'Remove Worker'"
                  variant="danger"
                  size="sm"
                  class="ml-1"
                  @click="onRemoveEmployee(worker, i, index)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
              <b-row class="align-items-center pt-1">
                <!-- Region -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required|not-null"
                    name="region"
                  >
                    <b-form-group label="Region">
                      <v-select
                        v-model="worker.region.id"
                        class="v-selection"
                        :options="regionOptions"
                        :reduce="item => item.value"
                        :clearable="false"
                        placeholder="Select a Region"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Coverage Quality -->
                <b-col
                  cols="12"
                  md="2"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required|not-null"
                    name="quality of coverage"
                  >
                    <b-form-group label="Quality of Coverage">
                      <b-form-select
                        v-model="worker.coverageQuality"
                        :options="qualityOfCoverageOptions"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Name -->
                <b-col
                  cols="12"
                  md="2"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="name"
                  >
                    <b-form-group label="Name">
                      <b-form-input
                        v-model="worker.name"
                        placeholder="Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Age -->
                <b-col
                  cols="6"
                  md="1"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required|min_value:1"
                    name="age"
                    :custom-messages="{ required: 'Required' }"
                  >
                    <b-form-group label="Age">
                      <b-form-input
                        v-model="worker.age"
                        placeholder="Age"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Gender -->
                <b-col
                  cols="6"
                  class="pl-0 px-md-1"
                  :md="i === 'system' ? 1 : 2"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required|not-null"
                    name="gender"
                    :custom-messages="{ required: 'Required' }"
                  >
                    <b-form-group label="Gender">
                      <b-form-select
                        v-model="worker.gender"
                        :options="genderOptions"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Salary -->
                <b-col
                  cols="12"
                  :md="i === 'system' ? 1 : 2"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required|min_value:1"
                    name="salary"
                    :custom-messages="{ required: 'Required' }"
                  >
                    <b-form-group label="Salary">
                      <b-form-input
                        v-model="worker.salary"
                        placeholder="Salary"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>

                  </validation-provider>
                </b-col>
                <!-- Work Modality, if the user is from Wz System -->
                <b-col
                  v-if="i === 'system'"
                  cols="12"
                  md="2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="work modality"
                    rules="required"
                  >
                    <b-form-group label="Select Work Modality">
                      <b-form-select
                        v-model="worker.workModality"
                        :options="workModalityOptions"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <div v-if="worker.region && worker.region.id">
                <h5>Employer Contributions</h5>
                <b-row>
                  <div
                    v-for="contribution in worker.contributions"
                    :key="contribution.id"
                    :class="contribution.coverageCategory.id ? 'col-md-2' : 'pl-0'"
                  >
                    <div v-if="contribution.coverageCategory.id">
                      <validation-provider
                        #default="{ errors }"
                        rules="percent"
                        name="employer contribution"
                      >
                        <b-form-group :label="contribution.coverageCategory.name">
                          <b-input-group append="%">
                            <b-form-input
                              v-model="contribution.contribution"
                              type="number"
                              placeholder="Contribution"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </div>
                  <b-col
                    v-for="category in getCoverageCategoriesByRegion(worker.region.id, worker.contributions)"
                    :key="category.id"
                    md="2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="percent"
                      name="employer contribution"
                    >
                      <b-form-group :label="category.name">
                        <b-input-group append="%">
                          <b-form-input
                            v-model="contributions.employees[`employee-${i}-${index}-${category.id}`]"
                            type="number"
                            placeholder="Contribution"
                            @change="value => addContribution(value, category.id, i, index)"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </div>

              <!-- Add Dependent -->
              <b-button
                variant="outline-primary"
                class="mt-2"
                @click="addDependent(worker)"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="text-primary"
                />
                Add dependent
              </b-button>

              <!-- Dependent Info -->
              <div
                v-if="worker.dependents && worker.dependents.length"
                class="mt-2"
              >
                <h5>Dependents</h5>

                <div
                  v-for="dependent,ind of worker.dependents"
                  :key="ind"
                  class="align-items-center mb-1"
                >
                  <b-row>
                    <b-col md="1">
                      <b>DEP {{ ind + 1 }}</b>
                    </b-col>
                    <b-col md="3">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="name"
                      >
                        <b-form-group label="Name">
                          <b-form-input
                            v-model="dependent.name"
                            placeholder="Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="2">
                      <validation-provider
                        #default="{ errors }"
                        rules="required|not-null"
                        name="quality of coverage"
                      >
                        <b-form-group label="Quality of Coverage">
                          <b-form-select
                            v-model="dependent.coverageQuality"
                            :options="qualityOfCoverageOptions"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="6"
                      md="2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|min_value:1"
                        name="age"
                        :custom-messages="{ required: 'Required' }"
                      >
                        <b-form-group label="Age">
                          <b-form-input
                            v-model="dependent.age"
                            placeholder="Age"
                            type="number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="6"
                      md="2"
                      class="pl-0 px-md-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|not-null"
                        name="gender"
                      >
                        <b-form-group label="Gender">
                          <b-form-select
                            v-model="dependent.gender"
                            :options="genderOptions"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="2">
                      <validation-provider
                        #default="{ errors }"
                        rules="required|not-null"
                        name="relation"
                      >
                        <b-form-group label="Relation">
                          <b-form-select
                            v-model="dependent.relationship"
                            :options="dependentOptions"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>

                  <div
                    v-if="worker.region && worker.region.id"
                    class="mt-2"
                  >
                    <h5>Employer Contributions</h5>
                    <b-row>
                      <div
                        v-for="contribution in dependent.contributions"
                        :key="contribution.id"
                        :class="contribution.coverageCategory.id ? 'col-md-2' : 'pl-0'"
                      >
                        <div v-if="contribution.coverageCategory.id">
                          <validation-provider
                            #default="{ errors }"
                            rules="percent"
                            name="employer contribution"
                          >
                            <b-form-group :label="contribution.coverageCategory.name">
                              <b-input-group append="%">
                                <b-form-input
                                  v-model="contribution.contribution"
                                  type="number"
                                  placeholder="Contribution"
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </div>
                      </div>
                      <b-col
                        v-for="category in getCoverageCategoriesByRegion(worker.region.id, dependent.contributions)"
                        :key="category.id"
                        md="2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="percent"
                          name="employer contribution"
                        >
                          <b-form-group :label="category.name">
                            <b-input-group append="%">
                              <b-form-input
                                type="number"
                                placeholder="Contribution"
                                @change="value => addContribution(value, category.id, i, index, ind)"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>

              <hr style="background-color:blue; height:3px;">
            </div>
          </validation-observer>
        </div>

        <div class="d-flex justify-content-end m-2">
          <b-button
            class="mx-2"
            @click="() => { $bvModal.hide('inputProviderModal') }"
          >
            Close
          </b-button>

          <b-button
            variant="primary"
            :disabled="!(employees && Object.values(employees).length) && (!allWorkers.os.length && !allWorkers.rm.length)"
            @click="handleSubmit"
          >
            Submit
          </b-button>
        </div>
      </div>
    </b-overlay>

    <!-- Company Name Model -->
    <b-modal
      :id="confirmModal === 'recommended-employees-modal' ? 'rec-temp-company-name-modal' : 'temp-company-name-modal'"
      title="Get Started"
      no-close-on-backdrop
      @ok="validateCompanyName"
    >
      <validation-observer ref="calculatorCompanyForm">
        <validation-provider
          #default="{ errors }"
          rules="required"
          name="company name"
        >
          <b-form-group label="Company Name">
            <b-form-input
              v-model="companyName"
              type="text"
              placeholder="Company Name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </validation-observer>
    </b-modal>

    <!-- Confirmation Modal -->
    <confirm-modal
      title="Remove Worker"
      :modal="confirmModal"
      :description="`Are you sure you want to remove this employee${confirmModal === 'recommended-employees-modal' ? ' from recommendation' : ''}?`"
      ok-variant="danger"
      @confirm="onRemoveConfirm"
      @cancel="() => {$bvModal.hide('remove-worker-modal')}"
    />
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput, BRow, BCol, BButton, BModal, BOverlay, BFormSelect, VBTooltip, BInputGroup,
} from 'bootstrap-vue'
import {
  ValidationProvider, ValidationObserver, extend,
} from 'vee-validate'
import { required, min_value } from 'vee-validate/dist/rules'
// eslint-disable-next-line no-unused-vars
import useApollo from '@/plugins/graphql/useApollo'
import vSelect from 'vue-select'
import ConfirmModal from '../../Wallet/components/reusables/ConfirmModal.vue'

extend('not-null', (field, value) => {
  // eslint-disable-next-line no-restricted-globals
  if (!value) return `The ${field} field is required`
  return true
})

extend('percent', value => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value)) return 'Employer contribution should be a number'
  if (value >= 0 && value <= 100) return true
  return 'Employer contribution should be between 0 and 100'
})

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormGroup,
    BFormInput,
    BOverlay,
    BFormSelect,
    BModal,
    BRow,
    BCol,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BInputGroup,
    vSelect,
    ConfirmModal,
  },
  props: {
    activeInputType: {
      type: [Array, Object],
      default: () => {},
    },
    activeInputChild: {
      type: [Array, Object],
      default: () => {},
    },
    employees: {
      type: Object,
      default: () => {},
    },
    company: {
      type: Object,
      default: () => {},
    },
    confirmModal: {
      type: String,
      default: () => 'remove-worker-modal',
    },
    regions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      required,
      min_value,
      showAdjusted: true,
      companyName: '',
      genderOptions: [
        { value: 'MALE', text: 'Male' },
        { value: 'FEMALE', text: 'Female' },
      ],
      dependentOptions: [
        { value: null, text: 'Select Dependent Relation' },
        { value: 'SPOUSE', text: 'Spouse' },
        { value: 'CHILD', text: 'Child' },
      ],
      qualityOfCoverageOptions: [
        { value: 'LOW', text: 'A-Low Coverage' },
        { value: 'MID', text: 'B-Mid Coverage' },
        { value: 'HIGH', text: 'C-High Coverage' },
      ],
      workModalityOptions: [
        { value: null, text: 'Select a Work Modality' },
        { value: 'REMOTE', text: 'Remote Domestic' },
        { value: 'REMOTEOFFSHORE', text: 'Remote Offshore' },
        { value: 'ONSITE', text: 'On Site' },
      ],
      totalOSRSalary: 0,
      totalRMSalary: 0,
      allWorkers: {
        os: [],
        rm: [],
        systemUsers: [],
      },
      selectedWorker: null,
      isLoading: false,
      contributions: {
        employees: {},
        dependents: {},
      },
    }
  },
  computed: {
    regionOptions() {
      return this.regions.map(region => ({
        value: region.id,
        label: `${region.country} - ${region.city}`,
      }))
    },
  },
  watch: {
    'employees.onSite': {
      handler(val) {
        if (val) {
          if (val.length) {
            val.forEach(employee => {
              if (!this.allWorkers.os.includes(employee)) this.allWorkers.os.push(employee)
            })
          }
          this.allWorkers.os.forEach(employee => {
            if (!val.includes(employee)) this.allWorkers.os.splice(this.allWorkers.os.indexOf(employee), 1)
          })
        }
      },
    },
    'employees.remote': {
      handler(val) {
        if (val) {
          if (val.length) {
            val.forEach(employee => {
              if (!this.allWorkers.rm.includes(employee)) this.allWorkers.rm.push(employee)
            })
          }
          this.allWorkers.rm.forEach(employee => {
            if (!val.includes(employee)) this.allWorkers.rm.splice(this.allWorkers.rm.indexOf(employee), 1)
          })
        }
      },
    },
    'employees.remoteOffshore': {
      handler(val) {
        if (val) {
          if (val.length) {
            val.forEach(employee => {
              if (!this.allWorkers.rmo.includes(employee)) this.allWorkers.rmo.push(employee)
            })
          }
          this.allWorkers.rmo.forEach(employee => {
            if (!val.includes(employee)) this.allWorkers.rmo.splice(this.allWorkers.rmo.indexOf(employee), 1)
          })
        }
      },
    },
    'employees.systemUsers': {
      handler(val) {
        if (val) {
          if (val.length) {
            val.forEach(employee => {
              if (!this.allWorkers.system.includes(employee)) this.allWorkers.system.push(employee)
            })
          }
          this.allWorkers.system.forEach(employee => {
            if (!val.includes(employee)) this.allWorkers.system.splice(this.allWorkers.system.indexOf(employee), 1)
          })
        }
      },
    },
  },
  mounted() {
    this.allWorkers = {
      os: this.employees?.onSite ? JSON.parse(JSON.stringify(this.employees.onSite)) : [],
      rm: this.employees?.remote ? JSON.parse(JSON.stringify(this.employees.remote)) : [],
      // eslint-disable-next-line no-nested-ternary
      ...(this.confirmModal === 'recommended-employees-modal'
        ? {
          rmo: this.employees?.remoteOffshore ? JSON.parse(JSON.stringify(this.employees.remoteOffshore)) : [],
          system: this.employees?.systemUsers ? JSON.parse(JSON.stringify(this.employees.systemUsers)) : [],
        } : []),
    }
  },
  methods: {
    getCoverageCategoriesByRegion(region, contributions) {
      const allCategories = this.regions.find(reg => reg.id === region).coverageCategories.data
      const employerContribution = contributions.map(contribution => contribution.coverageCategory.id)
      return allCategories.filter(category => !employerContribution.includes(category.id))
    },
    addContribution(value, category, type, index, depIndex = null) {
      if (depIndex !== null) {
        if (this.allWorkers[type][index].dependents[depIndex].contributions.find(con => con.coverageCategory.id === category)) {
          this.allWorkers[type][index].dependents[depIndex].contributions.find(con => con.coverageCategory.id === category).contribution = value
        } else {
          this.allWorkers[type][index].dependents[depIndex].contributions.push({
            contribution: value,
            coverageCategory: category,
          })
        }
      } else if (this.allWorkers[type][index].contributions.find(con => con.coverageCategory.id === category)) {
        this.allWorkers[type][index].contributions.find(con => con.coverageCategory.id === category).contribution = value
      } else {
        this.allWorkers[type][index].contributions.push({
          contribution: value,
          coverageCategory: category,
        })
      }
    },
    fetchAllWorkers() {
      useApollo.calculator.fetchInputProviders().then(res => {
        this.allWorkers = res.data
      })
    },
    addWorker(type) {
      const worker = {
        employeeType: type === 'os' ? 'ONSITE' : 'REMOTE',
        // id: this.allWorkers[type].length + 1,
        name: null,
        age: null,
        salary: null,
        gender: 'MALE',
        region: {
          id: null,
        },
        coverageQuality: 'LOW',
        dependents: [],
        contributions: [],
      }

      this.allWorkers[type].push(worker)
    },

    addDependent(worker) {
      const newDependents = {
        name: '',
        age: '',
        relationship: null,
        gender: 'MALE',
        coverageQuality: 'LOW',
        contributions: [],
      }
      worker.dependents.push(newDependents)
    },
    handleSubmit(bvModal) {
      bvModal.preventDefault()

      let valid = 1
      const validate = new Promise(resolve => {
        this.$refs.calculatorForm.forEach((form, index) => {
          form.validate().then(success => {
            if (!success) valid += 1
            setTimeout(() => {
              if (index === this.$refs.calculatorForm.length - 1) resolve()
            }, 100)
          })
        })
      })
      validate.then(() => {
        if (valid === 1) {
          if (!this.company?.id) {
            if (this.confirmModal === 'recommended-employees-modal') this.$bvModal.show('rec-temp-company-name-modal')
            else this.$bvModal.show('temp-company-name-modal')
          } else this.updateEmployees()
        }
      })
    },
    validateCompanyName(bvModal) {
      bvModal.preventDefault()
      this.$refs.calculatorCompanyForm.validate().then(success => {
        if (success) this.updateEmployees()
      }).finally(() => { this.$bvModal.hide('temp-company-name-modal') })
    },
    updateEmployees() {
      this.isLoading = true
      const type = ['os', 'rm']
      if (this.confirmModal === 'recommended-employees-modal') type.push('rmo', 'system')
      const data = []

      type.forEach(t => {
        data.push(...this.allWorkers[t].map(({
          __typename, isAdmin, companyRoles, companies, dependents, contributions, ...emp
        }) => ({
          employee: {
            ...emp,
            age: Number(emp.age),
            salary: Number(emp.salary),
            // eslint-disable-next-line no-nested-ternary
            workModality: t === 'os' ? 'ONSITE' : t === 'rm' ? 'REMOTE' : 'REMOTEOFFSHORE',
            operationType: this.activeInputChild.name.toUpperCase(),
            employeeType: this.getEmployeeType(this.activeInputType.class),
            // eslint-disable-next-line no-nested-ternary
            isCurrent: Number(emp.isCurrent) ? Number(emp.isCurrent) : this.confirmModal === 'recommended-employees-modal' ? 0 : 1,
            // eslint-disable-next-line no-nested-ternary
            isRecommended: Number(emp.isRecommended) ? Number(emp.isRecommended) : this.confirmModal === 'recommended-employees-modal' ? 1 : 0,
            region: emp.region.id,
            // eslint-disable-next-line no-shadow
            contributions: contributions.map(({ __typename, ...con }) => ({
              ...con,
              contribution: Number(con.contribution),
              coverageCategory: con.coverageCategory.id ?? con.coverageCategory,
            })),
          },
          dependents: {
            // eslint-disable-next-line no-shadow
            ...dependents.map(({ __typename, contributions, ...dep }) => ({
              ...dep,
              age: Number(dep.age),
              // eslint-disable-next-line no-shadow
              contributions: contributions.map(({ __typename, ...con }) => ({
                ...con,
                contribution: Number(con.contribution),
                coverageCategory: con.coverageCategory.id ?? con.coverageCategory,
              })),
            })),
          },
        })))
      })

      const company = this.company?.id ? { id: this.company.id } : { name: this.companyName }

      const params = {
        input: data,
        company,
      }

      useApollo.calculator.updateEmployees(params).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data.updateEmployees.message,
          },
        })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isLoading = false
        this.$bvModal.hide('inputProviderModal')
        this.$emit('employees-updated')
        if (!this.company?.id) {
          this.$emit('new-company-created')
        }
      })
    },
    getEmployeeType(type) {
      switch (type) {
        case 'salary':
          return 'SALARY'
        case 'hourly':
          return 'HOURLY'
        default:
          return 'CONTRACTEDPROVIDER'
      }
    },
    clearForm() {
      this.allWorkers = {
        os: [],
        rm: [],
      }
    },
    showConfirmModal(workerType, index) {
      this.selectedWorker = this.allWorkers[workerType][index]
      this.$nextTick(() => {
        this.$bvModal.show(this.confirmModal)
      })
    },
    onRemoveEmployee(worker, i, index) {
      if (worker.id) {
        if (this.confirmModal === 'recommended-employees-modal' && (worker.workModality === 'REMOTE' || worker.workModality === 'ONSITE')) {
          const existingEmployees = [
            ...(this.employees.remote ? this.employees.remote : []),
            ...(this.employees.onSite ? this.employees.onSite : []),
          ]
          const employee = existingEmployees.find(emp => emp.id === worker.id)
          if (employee && !employee.isRecommended) {
            this.removeWorker(i, index)
          } else this.showConfirmModal(i, index)
        } else this.showConfirmModal(i, index)
      } else if (worker.uuid) {
        const workerIndex = this.allWorkers.system.indexOf(this.allWorkers.system.find(employee => employee.uuid === worker.uuid))
        this.allWorkers.system.splice(workerIndex, 1)
        this.$emit('remove-employee', worker.uuid, 'system')
      } else this.removeWorker(i, index)
    },
    onRemoveConfirm() {
      this.isLoading = true
      const params = {
        id: this.selectedWorker.id,
        companyId: this.company.id,
        type: this.confirmModal === 'recommended-employees-modal' ? 'recommended' : 'current',
      }
      useApollo.calculator.removeEmployee(params).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data.removeEmployee.message,
          },
        })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        let type = ''
        switch (this.selectedWorker.workModality) {
          case 'ONSITE':
            type = 'os'
            break
          case 'REMOTE':
            type = 'rm'
            break
          case 'REMOTEOFFSHORE':
            type = 'rmo'
            break
          default:
            type = 'system'
        }
        const index = this.allWorkers[type].indexOf(this.allWorkers[type].find(worker => worker.id === this.selectedWorker.id))
        this.allWorkers[type].splice(index, 1)
        this.$emit('employees-updated')
        this.$emit('remove-employee', this.selectedWorker.id, type)
        this.isLoading = false
      })
    },
    removeWorker(workerType = null, index = null) {
      if (this.confirmModal === 'recommended-employees-modal') {
        if (this.selectedWorker || this.allWorkers[workerType][index].id) {
          const id = this.selectedWorker ? this.selectedWorker.id : this.allWorkers[workerType][index].id
          this.$emit('remove-employee', id, workerType)
        }
      }
      if (!workerType && !index) {
        const type = this.selectedWorker.workModality === 'ONSITE' ? 'os' : 'rm'
        const i = this.allWorkers[type].indexOf(this.allWorkers[type].find(worker => worker.id === this.selectedWorker.id))
        this.allWorkers[type].splice(i, 1)
      } else this.allWorkers[workerType].splice(index, 1)
    },
  },
}
</script>
